<template>
    <section id="job_applications_action_bar">
      <div>
        <JobApplicationSearch />
        <div class="filter-buttons">
          <button class="button-default toggle-filter-panel-btn" @click="toggleFiltersPanelDisplay"><v-icon v-if="this.isFiltersPanelDisplayed" name="minus-circle" /> <v-icon v-else name="plus-circle" /> Filtres</button>
          <button class="button-default remove-all-filters-btn" @click="resetFilters" v-if="this.filters.IsActive()"><v-icon name="times-circle" /> Supprimer les filtres</button>
        </div>
      </div>
      <div>
        <button id="sync_job_applications_btn" @click="loadJobApplications"><v-icon name="sync-alt" /></button>
      </div>
    </section>
</template>
<script>

import JobApplicationSearch from '@/components/jobApplications/JobApplicationSearch'
import { mapGetters, mapActions } from 'vuex'
import { JobApplicationFilter } from '@/models/JobApplicationFilter'

export default {
  name: 'JobApplicationsActionBar',
  computed: {
    ...mapGetters([
      'isFiltersPanelDisplayed',
      'jobApplications',
      'filters'
    ])
  },
  methods: {
    ...mapActions([
      'filterJobApplications',
      'loadJobApplications'
    ]),
    resetFilters () {
      this.filterJobApplications(new JobApplicationFilter())
    },
    toggleFiltersPanelDisplay () {
      this.$store.dispatch('toggleFiltersPanelDisplay')
    }
  },
  components: {
    JobApplicationSearch
  }
}
</script>
