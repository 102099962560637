import Vue from 'vue'
import Axios from 'axios'
import appSettings from '@/plugins/appSettings'
import { JobApplicationFilter } from '@/models/JobApplicationFilter'
import { JobApplicationCount } from '@/models/JobApplicationCount'
import { JobApplicationStatistics } from '@/models/JobApplicationStatistics'

Vue.use(appSettings)
const ROUTE = `${Vue.appSettings.jamApiBaseUrl}/Statistics`
const FILTERING_PREFIX = 'filter.'

export default {
  getJobApplicationCount (filters: JobApplicationFilter) {
    const searchParams = new URLSearchParams()
    const filterProperties = Object.entries(filters)
    filterProperties.forEach(property => {
      const propertyName = property[0]
      const propertyValue = property[1]
      if (propertyValue !== undefined && propertyValue !== null && propertyValue !== '') {
        if (Array.isArray(propertyValue)) {
          propertyValue.forEach(elem => searchParams.append(FILTERING_PREFIX + propertyName, elem))
        } else {
          searchParams.append(FILTERING_PREFIX + propertyName, propertyValue)
        }
      }
    })
    const params = {
      params: searchParams
    }
    return Axios.get(`${ROUTE}/GetJobApplicationCount`, params)
      .then(response => new JobApplicationCount().initialize(response.data))
  },
  getStatistics (fromDate: Date|null, toDate: Date|null) {
    const searchParams = new URLSearchParams()
    if (fromDate) {
      searchParams.append('fromDate', fromDate.toISOString())
    }
    if (toDate) {
      searchParams.append('toDate', toDate.toISOString())
    }
    const params = {
      params: searchParams
    }
    return Axios.get(`${ROUTE}/GetJobApplicationStatistics`, params)
      .then(response => new JobApplicationStatistics().initialize(response.data))
  }
}
