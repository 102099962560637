export class DisplaySettings {
    public IsFiltersPanelDisplayed: boolean
    public IsJobApplicationSidebarVisible: boolean
    public IsRecruitmentStepLocked: boolean

    constructor () {
      this.IsFiltersPanelDisplayed = false
      this.IsJobApplicationSidebarVisible = false
      this.IsRecruitmentStepLocked = false
    }
}
