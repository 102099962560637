import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

// import createPersistedState from 'vuex-persistedstate'
import { JobApplication } from '@/models/JobApplication'
import { MessageModel } from '@/models/MessageModel'
import { AttachmentBlobUrl } from '@/models/AttachmentBlobUrl'
import { JobApplicationAttachment } from '@/models/JobApplicationAttachment'
import { TamConfiguration } from '@/models/TamConfiguration'
import { TamUser } from '@/models/tamUser'
import { JobApplicationFilter } from '@/models/JobApplicationFilter'
import { JobApplicationSort } from '@/models/JobApplicationSort'
import { JobApplicationFilterReferential } from '@/models/JobApplicationFilterReferential'
import { RecruitmentStep } from '@/models/RecruitmentStep'
import { JobApplicationStatus } from '@/models/JobApplicationStatus'
import { JobApplicationCount } from '@/models/JobApplicationCount'
import { DisplaySettings } from '@/models/DisplaySettings'
import ReferentialService from '@/services/referential.service'
import { TalentApplyConfiguration } from '@/models/TalentApplyConfiguration'
import JobApplicationService from '@/services/jobApplication.service'
import StatisticsService from '@/services/statistics.service'
import CustomerSettingsService from '@/services/customerSettings.service'
import RecruitmentProcessService from '@/services/recruitmentProcess.service'
import TalentApplyService from '@/services/talentApply.service'
import AttachmentService from '@/services/attachment.service'
import { JobApplicationHeader } from '@/models/JobApplicationHeader'
import { JobApplicationRange } from '@/models/JobApplicationRange'
import { JobApplicationStatistics } from '@/models/JobApplicationStatistics'
import { AttachmentsInformation } from '@/models/AttachmentsInformation'

// TODO : externaliser dans un fichier de constantes
const jobApplicationApiPartUri = 'jobapplication'
const recruitmentProcessApiPartUri = 'recruitmentprocess'
const sendEmailProcessApiPartUri = 'SendEmail'
const JobOfferPublicationApiPartUri = 'JobOfferPublication'
const attachmentApiPartUri = 'attachment'
const logoutPartUri = 'Account/Logout.aspx'

// TODO : faire des modules pour découper le fichier

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // Global state
    displaySettings: new DisplaySettings(),
    isFiltersPanelDisplayed: false,
    isJobApplicationSidebarVisible: false,
    isRecruitmentStepLocked: false,
    attachmentBlobUrls: Array<AttachmentBlobUrl>(),
    currentUser: new TamUser(),

    // Referential state
    recruitmentSteps: Array<RecruitmentStep>(), // TODO : en attente du retour métier sur les status/steps
    statuses: Array<JobApplicationStatus>(),
    customMessageModelList: Array<MessageModel>(),

    // JobApplications state
    jobApplications: Array<JobApplication>(),
    selectedJobApplicationsIds: Array<string>(), // TODO : reporter comportement sur le pipeline
    filters: new JobApplicationFilter(),
    sorts: new JobApplicationSort(),
    filterReferential: new JobApplicationFilterReferential(),
    jobApplicationCount: new JobApplicationCount(),
    listHeader: new JobApplicationHeader(),
    jobApplicationsLoaderCounter: 0,

    // JobApplication state
    currentJobApplication: new JobApplication(),

    // Administration state
    tamConfiguration: new TamConfiguration(),
    talentApplyConfiguration: new TalentApplyConfiguration(),

    // Statistics state
    statistics: new JobApplicationStatistics()
  },
  getters: {
    /// //////////////////
    /// GLOBAL GETTERS ///
    /// //////////////////

    /// ///////////////////////
    /// REFERENTIAL GETTERS ///
    /// ///////////////////////

    /// ////////////////////////////
    /// JOB APPLICATIONS GETTERS ///
    /// ////////////////////////////
    jobApplications (state) {
      return state.jobApplications
    },

    /// ///////////////////////////
    /// JOB APPLICATION GETTERS ///
    /// ///////////////////////////

    currentJobApplication (state) {
      return state.currentJobApplication
    },
    selectedJobApplicationsIds (state) {
      return state.selectedJobApplicationsIds
    },
    jobApplication (state, id: string) {
      return state.jobApplications.find(x => x.Id === id)
    },
    isFiltersPanelDisplayed (state) {
      return state.isFiltersPanelDisplayed
    },
    isJobApplicationSidebarVisible (state) {
      return state.isJobApplicationSidebarVisible
    },
    isRecruitmentStepLocked (state) {
      return state.isRecruitmentStepLocked
    },
    currentSelectedStatusList (state) {
      let statusList = new Array<number>()
      const currentSelectedJobApplications = state.jobApplications.filter((x: JobApplication) => state.selectedJobApplicationsIds.includes(x.Id))
      if (currentSelectedJobApplications) {
        statusList = currentSelectedJobApplications.map((x: JobApplication) => x.Status)
      }
      return statusList
    },
    jobApplicantAcknowledgmentMessageModel (state) {
      return state.tamConfiguration.ApplicationAcknowledgmentMessage
    },
    positiveAnswerMessageModel (state) {
      return state.tamConfiguration.PositiveAnswerNotificationMessage
    },
    negativeAnswerMessageModel (state) {
      return state.tamConfiguration.NegativeAnswerNotificationMessage
    },
    customMessageModels (state) {
      return state.tamConfiguration.CustomMessageModels
    },
    customMessageModelList (state) {
      return state.customMessageModelList
    },
    stakeholders (state) {
      return state.tamConfiguration.Stakeholders
    },
    emailTrackingAddress (state) {
      return state.tamConfiguration.OpenApplicationEmailAddress
    },
    emailTrackingUrl (state) {
      return state.tamConfiguration.OpenApplicationUrl
    },
    attachmentBlobUrlDictionary (state) {
      return state.attachmentBlobUrls
    },
    currentUser (state) {
      return state.currentUser
    },
    displaySettings (state) {
      return state.displaySettings
    },
    filters (state) {
      return state.filters
    },
    sorts (state) {
      return state.sorts
    },
    listHeader (state) {
      return state.listHeader
    },
    jobApplicationsLoaderCounter (state) {
      return state.jobApplicationsLoaderCounter
    },
    filterReferential (state) {
      return state.filterReferential
    },
    recruitmentSteps (state) {
      return state.recruitmentSteps
    },
    statuses (state) {
      return state.statuses
    },
    jobApplicationCount (state) {
      return state.jobApplicationCount
    },
    tamConfiguration (state) {
      return state.tamConfiguration
    },
    talentApplyConfiguration (state) {
      return state.talentApplyConfiguration
    },
    defaultCleaningApplications (state) {
      return state.tamConfiguration.DefaultCleaningApplications
    },
    isTalentApplyEnabled (state) {
      return state.talentApplyConfiguration.TalentApplyActive
    },

    /// //////////////////////
    /// STATISTICS GETTERS ///
    /// //////////////////////
    statistics (state) {
      return state.statistics
    }
  },
  mutations: {
    addActivity (state, { jobApplicationId, activity }) {
      state.jobApplications.find((x) => x.Id === jobApplicationId).Activities.push(activity)
    },
    setJobApplications (state, jobApplications: JobApplication[]) {
      state.jobApplications = jobApplications
    },
    setCurrentJobApplication (state, jobApplication) {
      state.currentJobApplication = jobApplication
    },
    clearCurrentJobApplication (state) {
      state.currentJobApplication = new JobApplication()
    },
    setSelectedJobApplications (state, jobApplicationsIds: string[]) {
      state.jobApplicationCount.Selected = jobApplicationsIds.length
      state.selectedJobApplicationsIds = jobApplicationsIds
    },
    addSelectedJobApplication (state, jobApplicationId: string) {
      if (!state.selectedJobApplicationsIds.includes(jobApplicationId)) {
        state.selectedJobApplicationsIds.push(jobApplicationId)
        state.jobApplicationCount.Selected += 1
      }
    },
    updateJobApplicationGlobally (state, jobApplication) { // TODO : à reprendre dans le refresh
      const indexAll = state.jobApplications.findIndex((x) => x.Id === jobApplication.Id)
      if (indexAll > -1) {
        Vue.set(state.jobApplications, indexAll, jobApplication)
      }
    },
    removeSelectedJobApplication (state, jobApplicationId: string) {
      if (state.selectedJobApplicationsIds.includes(jobApplicationId)) {
        const index = state.selectedJobApplicationsIds.indexOf(jobApplicationId)
        if (index > -1) {
          state.selectedJobApplicationsIds.splice(index, 1)
          state.jobApplicationCount.Selected -= 1
        }
      }
    },
    toggleFiltersPanelDisplay (state) {
      state.isFiltersPanelDisplayed = !state.isFiltersPanelDisplayed
    },
    setJobApplicationsSidebarVisibility (state, isVisible) {
      state.isJobApplicationSidebarVisible = isVisible
    },
    deleteJobApplicationsFromState (state, jobApplicationsIds: Array<string>) {
      const newList = state.jobApplications.filter(x => !jobApplicationsIds.includes(x.Id))
      state.jobApplications = newList
    },
    setRecruitmentStepLock (state, isLocked) {
      state.isRecruitmentStepLocked = isLocked
    },
    addAttachmentBlobUrl (state, attachmentBlobUrl: AttachmentBlobUrl) {
      if (!state.attachmentBlobUrls) {
        state.attachmentBlobUrls = new Array<AttachmentBlobUrl>()
      }

      if (!state.attachmentBlobUrls.find(x => x.AttachmentId === attachmentBlobUrl.AttachmentId)) {
        state.attachmentBlobUrls.push(attachmentBlobUrl)
      }
    },
    clearAttachmentBlobUrls (state) {
      state.attachmentBlobUrls = new Array<AttachmentBlobUrl>()
    },
    setCurrentUser (state, user: TamUser) {
      state.currentUser = user
    },
    setTamConfiguration (state, tamConfiguration) {
      state.tamConfiguration = tamConfiguration
    },
    setTalentApplyConfiguration (state, talentApplyConfiguration) {
      state.talentApplyConfiguration = talentApplyConfiguration
    },
    setFilters (state, filters: JobApplicationFilter) {
      state.filters = filters
    },
    setSorts (state, sorts: JobApplicationSort) {
      state.sorts = sorts
    },
    setDisplaySettings (state, settings: DisplaySettings) {
      state.displaySettings = settings
    },
    setFilterReferential (state, referential: JobApplicationFilterReferential) {
      state.filterReferential = referential
    },
    setRecruitmentSteps (state, recruitmentSteps: RecruitmentStep[]) {
      state.recruitmentSteps = recruitmentSteps
    },
    setStatuses (state, statuses: JobApplicationStatus[]) {
      state.statuses = statuses
    },
    setJobApplicationCount (state, count: JobApplicationCount) {
      state.jobApplicationCount = count
    },
    setCustomMessageModelList (state, customMessageModels: MessageModel[]) {
      state.customMessageModelList = customMessageModels
    },
    increaseJobApplicationsLoaderCounter (state) {
      state.jobApplicationsLoaderCounter += 1
    },
    decreaseJobApplicationsLoaderCounter (state) {
      state.jobApplicationsLoaderCounter -= 1
    },
    setStatistics (state, statistics: JobApplicationStatistics) {
      state.statistics = statistics
    },
    updateSelectedJobApplicationsIds (state) {
      const newList = new Array<string>()
      state.selectedJobApplicationsIds.forEach(selectedJobApplicationsId => {
        if (state.jobApplications.find(x => x.Id === selectedJobApplicationsId)) {
          newList.push(selectedJobApplicationsId)
        }
        state.selectedJobApplicationsIds = newList
      })
    }

  },
  actions: {
    initialization ({ getters, commit }) {
      ReferentialService.getJobApplicationFilterReferential()
        .then(filterReferential => { commit('setFilterReferential', filterReferential) })
      ReferentialService.getJobApplicationStatuses()
        .then(statuses => { commit('setStatuses', statuses) })
      CustomerSettingsService.getRecruitmentSteps()
        .then(recruitmentSteps => { commit('setRecruitmentSteps', recruitmentSteps) })
      commit('clearAttachmentBlobUrls')
    },
    loadJobApplications ({ getters, commit }) {
      commit('increaseJobApplicationsLoaderCounter')
      JobApplicationService.getAll(getters.filters, getters.sorts, new JobApplicationRange())
        .then(result => {
          commit('setJobApplications', result)
          commit('updateSelectedJobApplicationsIds')
          commit('decreaseJobApplicationsLoaderCounter')
        })
        .catch(errors => {
          console.log(errors)
          commit('decreaseJobApplicationsLoaderCounter')
        })
    },
    loadNextJobApplications ({ getters, commit }) { // TODO : à éxecuter après chaque scroll
      const range = new JobApplicationRange().initialize(getters.jobApplications.length, 50)
      JobApplicationService.getAll(getters.filters, getters.sorts, range)
        .then(result => {
          const nextJobApplications = getters.jobApplications
          result.forEach((jobApplication: JobApplication) => {
            if (getters.jobApplications.findIndex((ja: JobApplication) => ja.Id === jobApplication.Id) < 0) {
              nextJobApplications.push(jobApplication)
            }
          })
          commit('setJobApplications', nextJobApplications)
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    refreshCurrentJobApplication ({ getters, commit }) {
      Axios.get(`${Vue.appSettings.jamApiBaseUrl}/${jobApplicationApiPartUri}/get/${getters.currentJobApplication.Id}`)
        .then(response => new JobApplication().initialize(response.data.jobApplication))
        .then(jobApplicationResult => {
          commit('setCurrentJobApplication', jobApplicationResult)
        })
        .catch(console.error)
    },
    openJobApplicationById ({ getters, commit, dispatch }, jobApplicationId) {
      RecruitmentProcessService.open(jobApplicationId)
        .then(jobApplicationResult => {
          commit('setCurrentJobApplication', jobApplicationResult)
          if (getters.currentJobApplication.Status === 3 || getters.currentJobApplication.Status === 4) {
            dispatch('setRecruitmentStepLock', true)
          } else {
            dispatch('setRecruitmentStepLock', false)
          }
        })
        .catch(function (error) {
          console.error(error.message)
        })
    },
    openJobApplicationOnResumeDownload ({ getters, commit }, jobApplicationId) {
      RecruitmentProcessService.open(jobApplicationId)
        .then(jobApplicationResult => {
          commit('updateJobApplicationGlobally', jobApplicationResult)
        })
        .catch(function (error) {
          console.error(error.message)
        })
    },
    initCurrentJobApplication ({ dispatch }, jobApplicationId) {
      dispatch('openJobApplicationById', jobApplicationId)
      dispatch('unselectAllJobApplications')
      dispatch('selectJobApplication', jobApplicationId)
    },
    clearCurrentJobApplication ({ commit }) {
      commit('clearCurrentJobApplication')
    },
    hireApplicant ({ dispatch }, jobApplicationId) {
      RecruitmentProcessService.hire(jobApplicationId)
        .then(function () {
          dispatch('refreshCurrentJobApplication')
        })
        .catch(function (error) {
          console.error(error.message)
        })
    },
    rejectApplicant ({ dispatch }, jobApplicationId) {
      RecruitmentProcessService.reject(jobApplicationId)
        .then(function () {
          dispatch('refreshCurrentJobApplication')
        })
        .catch(function (error) {
          console.error(error.message)
        })
    },
    // TODO: passer par le service API
    contactApplicant ({ getters, dispatch }, data) {
      const selectedJobApplicationsInformations: Array<{ ApplicantEmail: string; JobOfferId: string }> = []

      if (getters.currentJobApplication?.Applicant?.Email) {
        selectedJobApplicationsInformations.push({
          ApplicantEmail: getters.currentJobApplication?.Applicant?.Email || '', // Ajustez le cas où l'email est null ou undefined
          JobOfferId: getters.currentJobApplication?.JobOffer?.Id || ''
        })
      } else {
        getters.selectedJobApplicationsIds.forEach((jobApplicationId: string) => {
          const jobApplication = getters.jobApplications.find((x: JobApplication) => x.Id === jobApplicationId)
          if (jobApplication !== undefined && jobApplication !== null) {
            selectedJobApplicationsInformations.push({ ApplicantEmail: jobApplication?.Applicant?.Email, JobOfferId: jobApplication?.JobOffer?.Id })
          }
        })
      }

      Axios.post(`${Vue.appSettings.emailServiceApiBaseUrl}/${sendEmailProcessApiPartUri}/SendContactApplicantsEmail`, {
        Subject: data.messageObject,
        Body: data.messageContent,
        SelectedJobApplicationsInformations: selectedJobApplicationsInformations
      })
        .then(response => {
          if (response.status === 202) {
            if (getters.currentJobApplication?.Applicant?.Email) {
              JobApplicationService.contact(getters.currentJobApplication.Id, data)
                .then(result => {
                  dispatch('refreshCurrentJobApplication')
                })
                .catch(console.error)
            } else {
              getters.selectedJobApplicationsIds.forEach((jobApplicationId: string) => {
                JobApplicationService.contact(jobApplicationId, data)
                  .catch(console.error)
              }
              )
            }
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    getApplicantJobApplications ({ state }, email: string) {
      return JobApplicationService.getApplicantJobApplications(email)
    },
    reopenJobApplication ({ dispatch }, jobApplicationId) {
      RecruitmentProcessService.reopen(jobApplicationId)
        .then(function () {
          dispatch('refreshCurrentJobApplication')
        })
        .catch(function (error) {
          console.error(error.message)
        })
    },
    moveToNextRecruitmentStep ({ dispatch }, { jobApplicationId, step }) {
      RecruitmentProcessService.moveToNextRecruitmentStep(jobApplicationId, step)
        .then(function () {
          dispatch('refreshCurrentJobApplication')
        })
        .catch(function (error) {
          console.error(error.message)
        })
    },
    hireSelectedApplicants ({ getters, dispatch }) {
      getters.selectedJobApplicationsIds.forEach((jobApplicationId: number) => {
        RecruitmentProcessService.hire(jobApplicationId)
          .then(function () {
            dispatch('loadJobApplications')
          })
          .catch(function (error) {
            console.error(error.message)
          })
      })
    },
    rejectSelectedApplicants ({ getters, dispatch }) {
      getters.selectedJobApplicationsIds.forEach((jobApplicationId: number) => {
        RecruitmentProcessService.reject(jobApplicationId)
          .then(function () {
            dispatch('loadJobApplications')
          })
          .catch(function (error) {
            console.error(error.message)
          })
      })
    },
    openSelectedJobApplications ({ getters, dispatch }) {
      getters.selectedJobApplicationsIds.forEach((jobApplicationId: number) => {
        RecruitmentProcessService.open(jobApplicationId)
          .then(function () {
            dispatch('loadJobApplications')
          })
          .catch(function (error) {
            console.error(error.message)
          })
      })
    },
    reopenSelectedJobApplications ({ getters, dispatch }) {
      getters.selectedJobApplicationsIds.forEach((jobApplicationId: number) => {
        RecruitmentProcessService.reopen(jobApplicationId)
          .then(function () {
            dispatch('loadJobApplications')
          })
          .catch(function (error) {
            console.error(error.message)
          })
      })
    },
    moveSelectedJobApplicationsToNextRecruitmentStep ({ getters, dispatch }, step) {
      console.log(step)
      getters.selectedJobApplicationsIds.forEach((jobApplicationId: number) => {
        RecruitmentProcessService.moveToNextRecruitmentStep(jobApplicationId, step)
          .then(function () {
            dispatch('loadJobApplications')
          })
          .catch(console.error)
      })
    },
    selectJobApplications ({ commit }, jobApplicationsIds) {
      commit('setSelectedJobApplications', jobApplicationsIds)
    },
    selectAllJobApplications ({ state, commit }) {
      commit('setSelectedJobApplications', state.jobApplications.map(ja => ja.Id))
    },
    selectJobApplication ({ commit, dispatch }, jobApplicationId) {
      commit('addSelectedJobApplication', jobApplicationId)
      dispatch('setJobApplicationsSidebarVisibility')
    },
    unselectJobApplication ({ commit, dispatch }, jobApplicationId) {
      commit('removeSelectedJobApplication', jobApplicationId)
      dispatch('setJobApplicationsSidebarVisibility')
    },
    unselectAllJobApplications ({ commit }) {
      commit('setSelectedJobApplications', [])
    },
    addGlobalSearchFilter ({ commit }, searchInput) {
      commit('addGlobalSearchFilter', searchInput)
    },
    addComment ({ getters, dispatch }, comment) {
      const params = {
        JobApplicationId: getters.currentJobApplication.Id,
        Comment: comment
      }
      Axios.post(`${Vue.appSettings.jamApiBaseUrl}/${jobApplicationApiPartUri}/AddComment`, params)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    modifyComment ({ getters, dispatch }, comment) {
      JobApplicationService.modifyComment(getters.currentJobApplication.Id, comment.Content, comment.Id)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    deleteSelectedComment ({ getters, dispatch }, commentId) {
      JobApplicationService.deleteComment(getters.currentJobApplication.Id, commentId)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    toggleFiltersPanelDisplay ({ commit }) {
      commit('toggleFiltersPanelDisplay')
    },
    setJobApplicationsSidebarVisibility ({ commit, getters }) {
      const isVisible = getters.selectedJobApplicationsIds.length > 0
      commit('setJobApplicationsSidebarVisibility', isVisible) // TODO : splitter la méthode en deux ? (show et hide)
    },
    deleteSelectedJobApplications ({ getters, dispatch }) {
      dispatch('deleteJobApplications', getters.selectedJobApplicationsIds)
    },
    deleteJobApplications ({ commit, dispatch }, jobApplicationIds) {
      const requests = new Array<any>()
      jobApplicationIds.forEach((jobApplicationIdToDelete: string) => {
        requests.push(JobApplicationService.delete(jobApplicationIdToDelete))
      })
      return Axios.all(requests)
        .then(Axios.spread((...responses) => {
          if (responses.every((response: any) => response.status === 200 || response.status === 204)) {
            commit('deleteJobApplicationsFromState', jobApplicationIds)
          } else {
            dispatch('loadJobApplications')
          }
        }))
        .catch(errors => {
          console.log(errors)
          dispatch('loadJobApplications')
        })
        .finally(() => {
          dispatch('unselectAllJobApplications')
        })
    },
    // TODO: passer par le service API
    setNewApplicationNotificationSetting ({ dispatch }, value) {
      Axios.put(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/SetNewJobApplicationNotificationSetting/${value}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    setApplicantAcknowledgment ({ dispatch }, value) {
      Axios.put(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/SetApplicationAcknowledgment/${value}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    setPositiveAnswer ({ dispatch }, value) {
      Axios.put(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/SetPositiveAnswerNotificationSetting/${value}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    setNegativeAnswer ({ dispatch }, value) {
      Axios.put(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/SetNegativeAnswerNotificationSetting/${value}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    addCustomMessageModel ({ dispatch }, messageModel) {
      const newMessageModel = {
        Type: messageModel.Type,
        Title: messageModel.Title,
        Object: messageModel.Subject,
        Body: messageModel.Body
      }
      Axios.post(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/AddCustomMessageModel`, newMessageModel)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    editCustomMessageModel ({ dispatch }, messageModel) {
      const newMessageModel = {
        Id: messageModel.Id,
        Title: messageModel.Title,
        Object: messageModel.Subject,
        Body: messageModel.Body
      }
      Axios.put(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/EditCustomMessageModel`, newMessageModel)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    deleteCustomMessageModel ({ dispatch }, messageModelId) {
      Axios.delete(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/DeleteCustomMessageModel/${messageModelId}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    saveNotificationMessageModel ({ dispatch }, messageModel) {
      const newMessageModel = {
        Type: messageModel.Type,
        Title: messageModel.Title,
        Sender: messageModel.Sender,
        Object: messageModel.Subject,
        Body: messageModel.Body
      }
      Axios.post(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/SaveNotificationMessageModel`, newMessageModel)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    deleteNotificationMessageModel ({ dispatch }, messageModelType) {
      Axios.delete(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/DeleteNotificationMessageModel/${messageModelType}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    setErrorDuplicatesSetting ({ dispatch }, settingValue: boolean) {
      return CustomerSettingsService.setErrorDuplicatesSetting(settingValue)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('setErrorDuplicatesSetting', response.status)
          }
        })
        .catch(console.error)
    },
    setMultiApplicationDuplicatesSetting ({ dispatch }, settingValue: boolean) {
      return CustomerSettingsService.setMultiApplicationDuplicatesSetting(settingValue)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('setMultiApplicationDuplicatesSetting', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    updateJobApplicationRatingById ({ dispatch }, { jobApplicationId, rating }) {
      Axios.put(`${Vue.appSettings.jamApiBaseUrl}/${jobApplicationApiPartUri}/rate`, { id: jobApplicationId, rating: Number(rating) })
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    updateApplicantNames ({ dispatch }, { jobApplicationId, firstName, lastName }) {
      return JobApplicationService.modifyApplicantNames(jobApplicationId, firstName, lastName)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    updateApplicantEmail ({ dispatch }, { jobApplicationId, email }) {
      Axios.put(`${Vue.appSettings.jamApiBaseUrl}/${jobApplicationApiPartUri}/modifyapplicantemail`, { jobApplicationId: jobApplicationId, applicantEmail: email })
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    addJobApplicationTag ({ dispatch }, { jobApplicationId, tag }) {
      return JobApplicationService.addTagToJobApplication(jobApplicationId, tag)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
            dispatch('addTagsToLocalFilterReferential', { tags: [tag] })
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    addTagsToJobApplications ({ dispatch }, { jobApplicationIds, tags }) {
      return JobApplicationService.addTagsToJobApplications(jobApplicationIds, tags)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('loadJobApplications')
            dispatch('addTagsToLocalFilterReferential', { tags })
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    getMultiApplicationDuplicates (_, { jobOfferId, applicantEmail }) {
      return JobApplicationService.getMultiApplicationDuplicates(jobOfferId, applicantEmail)
    },
    addToJobApplicationDuplicatesCount (_, { jobApplicationId, nbToAdd }) {
      return JobApplicationService.addToJobApplicationDuplicatesCount(jobApplicationId, nbToAdd)
    },
    addTagsToLocalFilterReferential ({ state, commit }, { tags }) {
      if (tags && tags.length) {
        tags.forEach((tag: string) => {
          const tagAlreadyExist = state.filterReferential.Tags.indexOf(tag) !== -1
          if (!tagAlreadyExist) {
            state.filterReferential.Tags.push(tag)
          }
        })
        commit('setFilterReferential', state.filterReferential)
      }
    },
    // TODO: passer par le service API
    deleteJobApplicationTag ({ dispatch }, { jobApplicationId, tag }) {
      Axios.put(`${Vue.appSettings.jamApiBaseUrl}/${jobApplicationApiPartUri}/deletetag`, { jobApplicationId, tag })
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    deleteSelectedStakeholder ({ dispatch }, selectedStakeholderId) {
      Axios.delete(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/DeleteStakeholder/${selectedStakeholderId}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    setRecruitmentStepLock ({ commit }, isLocked) {
      commit('setRecruitmentStepLock', isLocked)
    },
    // TODO: passer par le service API
    updateStakeholder ({ dispatch }, stakeholder) {
      const newStakeholder = {
        Id: stakeholder.Id,
        Name: stakeholder.Name,
        Email: stakeholder.Email
      }
      Axios.put(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/EditStakeholder`, newStakeholder)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    addStakeholder ({ dispatch }, stakeholder) {
      const newStakeholder = {
        Name: stakeholder.Name,
        Email: stakeholder.Email
      }
      Axios.post(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/AddStakeholder`, newStakeholder)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    // TODO: passer par le service API
    transferJobApplication ({ dispatch, state }, message) {
      const transferMessage = {
        receivers: message.receivers,
        messageObject: message.messageObject,
        messageContent: message.messageContent
      }
      JobApplicationService.transfer(message.jobApplicationIds, transferMessage)
        .then(response => {
          if (state.currentJobApplication.Id !== '') {
            dispatch('refreshCurrentJobApplication')
          }
        })
        .catch(console.error)
    },
    generateAttachmentBlobUrl ({ getters, commit }, attachment: JobApplicationAttachment) {
      return new Promise((resolve, reject) => {
        let attachmentBlobUrl = getters.attachmentBlobUrlDictionary.find((x: AttachmentBlobUrl) => x.AttachmentId === attachment.Id)
        if (attachmentBlobUrl) {
          console.log('Already downloaded blob : ' + attachment.Id)
          resolve(attachmentBlobUrl)
        } else {
          // TODO : trouver pourquoi cela ne fonctionne pas...
          // AttachmentService.getAttachmentUrl(attachment)
          //   .then(url => {
          //     console.log(url)
          //     attachmentBlobUrl = url
          //     commit('addAttachmentBlobUrl', url)
          //     resolve(url)
          //   })
          //   .catch(error => {
          //     console.error(error)
          //     reject(error)
          //   })
          return Axios.get(`${Vue.appSettings.jamApiBaseUrl}/${attachmentApiPartUri}/${attachment.Id}/${attachment.Date}`, { responseType: 'blob' })
            .then(response => {
              const blob = new Blob([response.data], { type: attachment.ContentType })
              const blobUrl = URL.createObjectURL(blob)
              attachmentBlobUrl = new AttachmentBlobUrl(attachment.Id, blobUrl)
              commit('addAttachmentBlobUrl', attachmentBlobUrl)
              resolve(attachmentBlobUrl)
            })
            .catch(error => {
              console.error(error)
              reject(error)
            })
        }
      })
    },
    // TODO: passer par le service API
    updateDefaultCleaningApplications ({ dispatch }, defaultCleaningApplications) {
      Axios.put(`${Vue.appSettings.custApiBaseUrl}/TamConfiguration/SetDefaultCleaningApplications/${defaultCleaningApplications}`)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('getTamConfiguration')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    },
    setCurrentUser ({ commit }, tamUser: TamUser) {
      commit('setCurrentUser', tamUser)
    },
    logOut () {
      localStorage.clear()
      window.location.href = `${Vue.appSettings.broadcastPlatformUrl}/${logoutPartUri}`
    },
    getTamConfiguration ({ commit }) {
      return CustomerSettingsService.getConfiguration()
        .then(tamConfiguration => {
          commit('setTamConfiguration', tamConfiguration)
        })
        // TODO : Creer un modele d'erreur
        .catch(console.error)
    },
    updateDisplay ({ commit }, settings: DisplaySettings) {
      commit('setDisplaySettings', settings)
    },
    filterJobApplications ({ commit, dispatch }, filters: JobApplicationFilter) {
      commit('setFilters', filters)
      dispatch('loadJobApplications')
    },
    sortJobApplications ({ commit, dispatch }, sorts: JobApplicationSort) {
      commit('setSorts', sorts)
      dispatch('loadJobApplications')
    },
    loadStatistics ({ commit }, { fromDate, toDate }) {
      StatisticsService.getStatistics(fromDate, toDate)
        .then(statisticsResult => {
          commit('setStatistics', statisticsResult)
        })
        .catch(function (error) {
          console.error(error.message)
        })
    },
    calculateTalentApplyJobApplicationFiability ({ state }, jobApplication: JobApplication) {
      return TalentApplyService.calculateJobApplicationFiability(jobApplication)
    },
    publishJobApplicationToATS ({ state }, jobApplication: JobApplication) {
      return TalentApplyService.publishJobApplicationToATS(jobApplication, state.currentUser.CompanyId, state.talentApplyConfiguration.TargetAts)
    },
    getTalentApplyConfiguration ({ commit, state }) {
      return TalentApplyService.getTalentApplyConfiguration(state.currentUser.CompanyId)
        .then(talentApplyConfiguration => {
          commit('setTalentApplyConfiguration', talentApplyConfiguration)
        })
    },
    addNewAttachments ({ dispatch }, { id, files }) {
      const params = {
        JobApplicationId: id,
        Attachments: files
      }
      Axios.post(`${Vue.appSettings.jamApiBaseUrl}/${jobApplicationApiPartUri}/AddNewAttachments`, params)
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            dispatch('refreshCurrentJobApplication')
          } else {
            console.log('nok', response.status)
          }
        })
        .catch(console.error)
    }
  }
})
