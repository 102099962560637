import Vue from 'vue'
import Axios from 'axios'
import appSettings from '@/plugins/appSettings'
import { MessageModel } from '@/models/MessageModel'
import { TamConfiguration } from '@/models/TamConfiguration'
import { RecruitmentStep } from '@/models/RecruitmentStep'

Vue.use(appSettings)
const ROUTE_MESSAGE_MODELS = `${Vue.appSettings.custApiBaseUrl}/MessageModel`
const ROUTE_CONFIGURATION = `${Vue.appSettings.custApiBaseUrl}/TamConfiguration`
const ROUTE_RECRUITMENT_STEPS = `${Vue.appSettings.custApiBaseUrl}/RecruitmentStep`

export default {
  getCustomMessageModels () {
    return Axios.get(`${ROUTE_MESSAGE_MODELS}/GetCustomMessageModels`)
      .then(response => response.data.map((mm: any) => new MessageModel().initialize(mm)))
  },
  getConfiguration () {
    return Axios.get(`${ROUTE_CONFIGURATION}/Get`)
      .then(response => new TamConfiguration().initialize(response.data))
  },
  getRecruitmentSteps () {
    return Axios.get(`${ROUTE_RECRUITMENT_STEPS}/GetRecruitmentSteps`)
      .then(response => response.data.map((rs: any) => new RecruitmentStep().initialize(rs)))
  },
  setErrorDuplicatesSetting (settingValue: boolean) {
    return Axios.put(`${ROUTE_CONFIGURATION}/errorduplicatessetting/${settingValue}`)
  },
  setMultiApplicationDuplicatesSetting (settingValue: boolean) {
    return Axios.put(`${ROUTE_CONFIGURATION}/multiapplicationduplicatessetting/${settingValue}`)
  }
}
