<template>
  <section id="statistics_action_bar" >
    <div id="filters">
      <div class='filter-input'>
        <v-date-picker
          class='date-selector'
          :locale='locale'
          :input-props="{
            class: 'input-text-default',
            placeholder: 'À partir du',
            readonly: true}"
          v-model='fromDateValue'
          @popoverWillHide="refreshStatistics"
          :popover="{ placement: 'bottom', visibility: 'click' }"
        >
          <input
            slot-scope="{ inputProps, inputEvents }"
            :class="'input-text-default'"
            v-bind='inputProps'
            v-on='inputEvents'/>
        </v-date-picker>
      </div>
      <div class='filter-input'>
        <v-date-picker
          class='date-selector'
          :locale='locale'
          :input-props="{
            class: 'input-text-default',
            placeholder: 'Jusqu\'au',
            readonly: true}"
          v-model='toDateValue'
          @popoverWillHide="refreshStatistics"
          :popover="{ placement: 'bottom', visibility: 'click' }"
        >
          <input
            slot-scope="{ inputProps, inputEvents }"
            :class="'input-text-default'"
            v-bind='inputProps'
            v-on='inputEvents'/>
        </v-date-picker>
      </div>
      <button class="button-default remove-all-filters-btn" @click="resetFilters" v-if="!isFiltersEmpty"><v-icon name="times-circle" /> Supprimer les filtres</button>
    </div>
    <div id="actions">
      <div id="dropdown_icon">
        <button id= "statistics_big_button" @click="showSelectMenu" @blur="closeSelectMenu"><v-icon name="eye"/></button>
        <div id="dropdown_content" v-show="showSelectBoard">
          <button class="action-button" :class="{'action-button-selected': displayedBoard === 'global'}" @mousedown="selectBoard('global')">Globale</button>
          <button class="action-button" :class="{'action-button-selected': displayedBoard === 'statuses'}" @mousedown="selectBoard('statuses')">Par statut</button>
          <button class="action-button" :class="{'action-button-selected': displayedBoard === 'recruitmentSteps'}" @mousedown="selectBoard('recruitmentSteps')">Par étape</button>
        </div>
      </div>
      <button id= "statistics_big_button" @click="exportStatistics"><v-icon name="file-export"/></button>
      <button id= "statistics_big_button" @click="refreshStatistics"><v-icon name="sync-alt"/></button>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'StatisticsActionBar',
  data () {
    return {
      displayedBoard: 'global',
      showSelectBoard: false,
      toDateValue: '',
      fromDateValue: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    }
  },
  methods: {
    ...mapActions(['loadStatistics']),
    selectBoard (board) {
      this.displayedBoard = board
      this.$emit('selectBoard', board)
    },
    showSelectMenu () {
      this.showSelectBoard = !this.showSelectBoard
    },
    closeSelectMenu () {
      this.showSelectBoard = false
    },
    refreshStatistics () {
      let fromDate = ''
      let toDate = ''
      if (this.fromDateValue) {
        fromDate = new Date(this.$moment(this.fromDateValue).format('YYYY-MM-DD'))
      }
      if (this.toDateValue) {
        toDate = new Date(this.$moment(this.toDateValue).format('YYYY-MM-DD'))
      }
      this.loadStatistics({ fromDate: fromDate, toDate: toDate })
    },
    resetFilters () {
      this.fromDateValue = ''
      this.toDateValue = ''
      this.refreshStatistics()
    },
    exportStatistics () {
      switch (this.displayedBoard) {
        case 'global' : this.$emit('exportGlobalStatistics')
          break
        case 'statuses' : this.$emit('exportStatusesStatistics')
          break
        case 'recruitmentSteps' : this.$emit('exportRecruitmentStepsStatistics')
          break
        default : this.$emit('exportGlobalStatistics')
          break
      }
    }
  },
  computed: {
    locale () {
      return this.$appSettings.locale
    },
    isFiltersEmpty () {
      return (this.fromDateValue === '' && this.toDateValue === '')
    }
  }
}
</script>
